export interface IWikiItem {
  title: string;
  description: string;
  states: string[];
  links: Array<{
    url: string;
    label: string;
    states: string[];
  }>;
  top?: boolean;
}

export interface IWikiItems {
  title: string;
  items: Array<IWikiItem>;
}

export const wikiItems: Array<IWikiItems> = [
  {
    title: 'Pinnacle Realty Advisors',
    items: [
      {
        title: 'Contact Information',
        description:
          '1001 W Markham Street Suite 100, Little Rock, AR 72201<br>501-255-3504<br/>AREC Broker # PB00090954<br/>PR Advisors, LLC - Federal Tax ID#: 47-3615491',
        states: ['AR'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1j5mlYnEDp__K4ARJYSVIGzQlDX7UPhY_/view?usp=sharing',
            label: 'Policies and Procedures Manual',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Contact Information',
        description:
          '500 Westover Dr #30061, Sanford, NC 27330<br/>972-338-5441<br/>NCREC Broker # C37868<br/>PR Advisors, LLC - Federal Tax ID#: 47-3615491<br/>',
        states: ['NC'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1j5mlYnEDp__K4ARJYSVIGzQlDX7UPhY_/view?usp=sharing',
            label: 'Policies and Procedures Manual',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Contact Information',
        description:
          '777 Brickell Ave #500-94761, Miami, FL 33131<br/>972-338-5441<br/>FREC Broker # CQ1066768<br/>Pinnacle Realty Advisors, LLC - Federal Tax ID#: 47-3615491<br/>',
        states: ['FL'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1j5mlYnEDp__K4ARJYSVIGzQlDX7UPhY_/view?usp=sharing',
            label: 'Policies and Procedures Manual',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Contact Information',
        description:
          '717 Crockett St #201, Shreveport, LA 71101<br/>318-233-1045<br/>LREC Broker # BROK.995705218-CORP<br/>PR Advisors, LLC - Federal Tax ID#: 47-3615491<br/>',
        states: ['LA'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1j5mlYnEDp__K4ARJYSVIGzQlDX7UPhY_/view?usp=sharing',
            label: 'Policies and Procedures Manual',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Contact Information',
        description:
          '3824 Cedar Springs Rd #801-7724, Dallas, TX 75219<br/>972-338-5441<br/>TREC Broker # 9004209<br/>PR Advisors, LLC - Federal Tax ID#: 47-3615491',
        states: ['TX'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1VWhhRyqZNVnC-PQR3nUrcoU8n8Do3gH3/view?ts=63b60fd4',
            label: 'IABS',
            states: ['TX'],
          },
          {
            url: 'https://drive.google.com/file/d/1sL9uBtZKj_RKSQ5OesNd_LxZ6Pn3lvRW/view',
            label: 'PR Advisors, LLC license',
            states: ['TX'],
          },
          {
            url: 'https://drive.google.com/file/d/1NfLsKltAQ3l7aJknMU540qYH0R21wdQp/view',
            label: 'PR ADVISORS, LLC insurance acord',
            states: ['TX'],
          },
          {
            url: 'https://drive.google.com/file/d/1j5mlYnEDp__K4ARJYSVIGzQlDX7UPhY_/view?usp=sharing',
            label: 'Policies and Procedures Manual',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Contact Information',
        description:
          '2443 Fillmore St #380-6638, San Francisco, CA 94115<br/>415-500-4990<br/>DRE Broker # 02220139<br/>PR Advisors, LLC - Federal Tax ID#: 93 1485264',
        states: ['CA'],
        links: [],
      },
      {
        title: 'Agent Resource Library',
        description: '',
        states: ['CA'],
        links: [
          {
            url: 'https://drive.google.com/drive/folders/1U9fOuyAlPbIoxSGPlFvU8ybnYP3eOJki',
            label: 'California Agent Compliance & Reference Materials ',
            states: ['CA'],
          },
        ],
      },
      {
        title: 'LREC Agency-Disclosure-Pamphlet',
        description: '',
        states: ['LA'],
        links: [
          {
            url: 'https://drive.google.com/drive/u/1/folders/1UcxZ4OjgBt2TZ0rGtButKHplNB2VQuuD',
            label: 'OPEN LINK',
            states: ['LA'],
          },
        ],
      },
      {
        title: 'Onboarding',
        description: '',
        states: ['TX'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1BhRBLZNUHe2d7Ck1OPxUYxbUP8obAdAB/view?usp=sharing',
            label: 'Important Information to Keep',
            states: ['TX'],
          },
          {
            url: 'https://drive.google.com/file/d/1u3YapBIxZxwDk8RPyBcxsYRkncZkWmPm/view?usp=sharing',
            label: 'Association instructions',
            states: ['TX'],
          },
        ],
      },
      {
        title: 'Company Training and Events Calendar',
        description:
          'Access our company calendar for regular weekly, monthly and special events.',
        states: ['ALL'],
        links: [
          {
            url: 'https://calendly.com/pinnaclerealtyadvisors',
            label: 'Open link',
            states: ['ALL'],
          },
        ],
      },
    ],
  },
  {
    title: 'Templates',
    items: [
      {
        title: 'CDA Template',
        description: '',
        states: ['ALL'],
        links: [
          {
            url: 'https://www.youtube.com/watch?v=1MoSUez-7i0',
            label: 'VIDEO tutorial',
            states: ['ALL'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1XPu-856yOOza_J0X31upB6goRmJKkWc7/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'CDA Template',
            states: ['FL'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1NZ-bTNUGckB0ahi2Opj-xoXFsG1DjdiT/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'CDA Template',
            states: ['LA'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1h5IcOhA_e8tFM61yzAQszeshQaQ4JUx8/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'CDA Template',
            states: ['NC'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1VmyKIVjSJPF1zxFY-dWTDGUuAJxptOhH/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'CDA Template',
            states: ['AR'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1miihYgu76W6t45d1VXs6EMnfmTRBEGn2/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'CDA Template',
            states: ['TX'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/16-CsxK9LzergIIvCB3uU4jfp7YPmajck/edit?usp=drive_link&ouid=117865669105614729732&rtpof=true&sd=true',
            label: 'CDA Template',
            states: ['CA'],
          },
          {
            url: 'https://help.hq.pinnaclera.com/en/articles/7051771-filling-out-a-cda',
            label: 'Transaction fee table',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Lease Invoice Template',
        description: '',
        states: ['ALL'],
        links: [
          {
            url: 'https://docs.google.com/spreadsheets/d/1VmyKIVjSJPF1zxFY-dWTDGUuAJxptOhH/edit#gid=317002702',
            label: 'OPEN LINK',
            states: ['AR'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1nW_VkTJJ7auyuRAxVtNIDmoffJ4U--Di/edit#gid=510269279',
            label: 'OPEN LINK',
            states: ['FL'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1NZ-bTNUGckB0ahi2Opj-xoXFsG1DjdiT/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'OPEN LINK',
            states: ['LA'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1h5IcOhA_e8tFM61yzAQszeshQaQ4JUx8/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'OPEN LINK',
            states: ['NC'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1ZjhZ3toc874WIRmUAQyOP-QoT4KCy3c5/edit#gid=2088462812',
            label: 'OPEN LINK',
            states: ['TX'],
          },
          {
            url: 'https://docs.google.com/spreadsheets/d/1Ob3xqkZH9E4Cg8hvf9aR5yhUqsJbZGCw/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
            label: 'OPEN LINK',
            states: ['CA'],
          },
        ],
      },
      {
        title: 'W9',
        description: '',
        states: ['ALL'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1djC872URlPqMLPTdxr_dPYDDoDgSzeaS/view?usp=drive_link',
            label: 'OPEN LINK',
            states: ['CA'],
          },
          {
            url: 'https://drive.google.com/file/d/1D_GYnY85wR6L08qIQmAA_BaHWDWJcl43/view',
            label: 'OPEN LINK',
            states: ['AR'],
          },
          {
            url: 'https://drive.google.com/file/d/1D_GYnY85wR6L08qIQmAA_BaHWDWJcl43/view',
            label: 'OPEN LINK',
            states: ['LA'],
          },
          {
            url: 'https://drive.google.com/file/d/1D_GYnY85wR6L08qIQmAA_BaHWDWJcl43/view',
            label: 'OPEN LINK',
            states: ['NC'],
          },
          {
            url: 'https://drive.google.com/file/d/1D_GYnY85wR6L08qIQmAA_BaHWDWJcl43/view',
            label: 'OPEN LINK',
            states: ['TX'],
          },
          {
            url: 'https://drive.google.com/file/d/1D_GYnY85wR6L08qIQmAA_BaHWDWJcl43/view',
            label: 'OPEN LINK',
            states: ['FL'],
          },
        ],
      },
      {
        title: 'Broker to Broker Referrals',
        description: '',
        states: ['ALL'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1VFUISxIek4o_L1UI4eyCpm6dr1lCEQ_N/view?usp=sharing',
            label: 'Broker to Broker Referral Form',
            states: ['AR', 'LA', 'NC', 'TX', 'FL'],
          },
          {
            url: 'https://drive.google.com/file/d/1Q1XleV_wOUTaF5IBdRl3CEzqnbKoZBzB/view?usp=sharing',
            label: 'Broker to Broker Referral Form',
            states: ['CA'],
          },
        ],
      },
      {
        title: 'Request for Offer Presentation',
        description: '',
        states: ['ALL'],
        links: [
          {
            url: 'https://drive.google.com/file/d/1jI-KtV_Y7jCJoYDbNjER1y4pvlVJcz5Q/view?usp=sharing',
            label: 'Request Template',
            states: ['ALL'],
          },
        ],
      },
    ],
  },
  {
    title: 'Marketing',
    items: [
      {
        title: 'Logos',
        description: 'Download our logos and create content yourself.',
        states: ['ALL'],
        links: [
          {
            url: 'https://drive.google.com/drive/folders/1TylekWTGVSiW3xegEhIbgKTdii0SziLn?usp=sharing',
            label: 'DOWNLOAD',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'Pinnacle Style Guide',
        description:
          'We have created simple brand guidelines (fonts, color schemes, graphic elements) for any graphic content you might want to create.',
        states: ['ALL'],
        links: [
          {
            url: 'https://link.pinnaclera.com/style-guide?_gl=1*1hr91p1*_ga*Njk4NDkzNjkuMTY5MzgyNzExMA..*_ga_21LJ9PM58S*MTY5NDE1NTc0My4xOS4xLjE2OTQxNTYwNDguNjAuMC4w',
            label: 'DOWNLOAD',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'BuildASign',
        description:
          'Use BuildASign, our preferred partner, for yard signs, riders, directionals, frames, stakes, and business cards. Pinnacle branded templates or custom options available. Super fast delivery!',
        states: ['ALL'],
        links: [
          {
            url: 'https://pinnacle.buildasign.com/',
            label: 'ORDER NOW',
            states: ['ALL'],
          },
        ],
      },
      {
        title: 'AgentByDesign',
        description:
          'Access hundreds of free-to-use social media print marketing templates through our design studio partner, AgentByDesign.',
        states: ['ALL'],
        links: [
          {
            url: 'https://agentbydesignco.com/register/pinnaclera/',
            label: 'LOGIN',
            states: ['ALL'],
          },
        ],
      },
    ],
  },
];
