import React, { FormEvent, useEffect } from 'react';
import { IUserEditParams, IUserWithAddressObject } from '../../slice/types';
import { Header } from '../../styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import RouteConstants from 'app/routes';
import {
  selectUserDocusignIsGenerating,
  selectUserDataFetchStatus,
} from '../../slice/selectors';
import { useAdminSlice } from '../../slice';
import { States, LicenseStates } from '../../../UserSettings/States';
import { Plans } from '../../../UserSettings/Plans';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  DatePickerInput,
  InputDropdown,
  InputField,
} from '../../../../components/FormInputs';
import useResponsive from '../../../../hooks/useResponsive';
import { inputLinkValidation } from '../../../../../utils/helpers';
import { useGetTeams } from '../../../../queries/admin/team';
import { DropdownOptions } from '../../../../components/FormInputs/InputDropdown';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackBar } from '../../../../components/CustomSnackbar';
import { useDeleteUser, useGeUserAdmin } from '../../../../queries/admin/user';
import palette from '../../../../../theme/pallete';

interface Props {
  isEdit?: boolean;
  userId: string;
  data: IUserWithAddressObject;
}

type Inputs = {
  name: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  licenseState: string;
  uuid: string;
  gDriveLink: string;
  licenseNumber: string;
  dateFirstLicensed: string;
  licenseExpirationDate: string;
  localAssociation: string;
  stripeId: string;
  test: boolean;
  planCode: string;
  teamLead: boolean;
  teamId?: number | string;
  address: {
    streetAddress: string;
    state: string;
    zipCode: string;
    city: string;
  };
};

const UserAddEdit: React.FC<Props> = ({ isEdit, userId, data }) => {
  const isMobile = useResponsive('down', 565);
  const userDataFetchStatus = useSelector(selectUserDataFetchStatus);
  const docusignGenerationStatus = useSelector(selectUserDocusignIsGenerating);
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const { actions } = useAdminSlice();
  const history = useHistory();

  const form = useForm<Inputs>({
    defaultValues: {
      name: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      licenseState: '',
      uuid: '',
      gDriveLink: '',
      licenseNumber: '',
      dateFirstLicensed: '',
      licenseExpirationDate: '',
      test: false,
      teamLead: false,
      planCode: '',
      localAssociation: '',
      teamId: '',
      stripeId: '',
      address: {
        streetAddress: '',
        state: '',
        zipCode: '',
        city: '',
      },
    },
  });

  const { setError, handleSubmit, control, reset, setValue } = form;

  const teamId = useWatch({
    control,
    name: 'teamId',
    defaultValue: '',
  });

  useEffect(() => {
    if (data && isEdit) {
      const address = data.address;
      const userData = data.user;
      reset({
        ...userData,
        teamId: userData.team?.id ?? '',
        address: { ...address },
      });
    }
  }, [data, isEdit, reset]);

  const { data: Teams } = useGetTeams({
    options: {
      // reformat date to name/value pair
      select: data =>
        data.map(option => ({
          value: option.id,
          name: option.name,
        })),
    },
  });

  const generateDocusignDocuments = () => {
    dispatch(actions.generateUserDocusignDocument(userId));
  };

  function validate(userData): boolean {
    if (userData.teamLead) {
      if (userData.planCode.includes('member')) {
        setError('planCode', {
          type: 'manual',
          message: "User can't be a leader on the selected plan.",
        });

        return false;
      }

      if (userData.teamId === '') {
        setError('teamId', {
          type: 'manual',
          message: 'Team is required for team lead.',
        });

        return false;
      }
    }

    return true;
  }

  const handleFormSubmit = (data, event) => {
    event.preventDefault();

    const userData = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      licenseState: data.licenseState,
      test: data.test,
      uuid: data.uuid,
      gDriveLink: data.gDriveLink,
      licenseNumber: data.licenseNumber,
      dateFirstLicensed: data.dateFirstLicensed,
      licenseExpirationDate: data.licenseExpirationDate,
      streetAddress: data.address.streetAddress,
      addressState: data.address.state,
      zipCode: data.address.zipCode,
      city: data.address.city,
      phoneNumber: data.phoneNumber,
      planCode: data.planCode,
      localAssociation: data.localAssociation,
      stripeId: data.stripeId,
      teamId: data.teamId,
      teamLead: data.teamLead,
    };

    if (validate(userData)) {
      if (isEdit && data) {
        dispatch(
          actions.updateUser({
            userId: data.id,
            data: userData,
          }),
        );
      } else {
        dispatch(
          actions.createUser({
            data: userData,
          }),
        );
      }
    }
  };

  const handleClearTeam = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setValue('teamId', '');
  };

  const deleteMutation = useDeleteUser();

  return (
    <Box width={'100%'} sx={{ paddingBottom: '2rem' }}>
      <ScrollToTopOnMount />
      {!isEdit && (
        <Header>
          <Typography sx={{ pb: 1, textTransform: 'capitalize' }} variant="h4">
            Add new user
          </Typography>
        </Header>
      )}
      <Card elevation={2} sx={{ position: 'relative' }}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardContent>
              <Typography variant={'h6'} pt={1} pb={2}>
                Contact
              </Typography>
              <Stack
                direction={'row'}
                gap={2}
                width={'100%'}
                pb={2}
                flexWrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <Box width={'100%'}>
                  <InputField
                    control={control}
                    name="name"
                    label="First Name *"
                    rules={{ required: 'Please input user name' }}
                  />
                </Box>
                <Box width={'100%'}>
                  <InputField
                    control={control}
                    name="lastName"
                    label="Last Name *"
                    rules={{ required: 'Please input user last name' }}
                  />
                </Box>
              </Stack>
              <Controller
                name={'test'}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Switch checked={field.value} {...field} />}
                    label="This is a test user."
                  />
                )}
              />
              <Box width={'100%'} pt={2}>
                <InputDropdown
                  name={'planCode'}
                  label="Subscription plan *"
                  control={control}
                  options={Plans}
                  rules={{
                    required: 'Please select user subscription plan',
                  }}
                />
              </Box>

              <Box width={'100%'} pt={2}>
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  gap={2}
                  width={'100%'}
                >
                  <Stack
                    gap={2}
                    flexGrow={1}
                    flexWrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <InputDropdown
                      name={'teamId'}
                      label="Team"
                      control={control}
                      selectProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              visibility: teamId !== '' ? 'visible' : 'hidden',
                            }}
                          >
                            <IconButton onClick={handleClearTeam}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      options={Teams as unknown as DropdownOptions}
                    />
                  </Stack>
                  <Stack
                    gap={2}
                    flexGrow={1}
                    flexWrap={isMobile ? 'wrap' : 'nowrap'}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Controller
                      name={'teamLead'}
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={field.value} {...field} />}
                          label="Team leader."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>

              {isEdit && (
                <InputField
                  control={control}
                  name={'uuid'}
                  label={'UUID'}
                  textFieldProps={{
                    sx: { mt: 2 },
                    disabled: true,
                  }}
                  withCopyButton
                />
              )}
              <InputField
                control={control}
                name={'gDriveLink'}
                label={'Google Drive Link'}
                textFieldProps={{
                  sx: { mt: 2 },
                }}
                rules={{
                  validate: {
                    inputLinkValidation: value =>
                      inputLinkValidation(value) || 'Please enter a valid URL',
                  },
                }}
                withCopyButton
              />
              <Typography variant={'h6'} pt={2} pb={2}>
                Contact information
              </Typography>
              <Stack
                direction={'row'}
                gap={2}
                width={'100%'}
                flexWrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <InputField
                  control={control}
                  name="phoneNumber"
                  label="Phone number *"
                  rules={{
                    required: 'Please input user phone number',
                  }}
                />
                <InputField
                  control={control}
                  name="email"
                  label="Email address *"
                  rules={{
                    required: 'Please input user email address',
                  }}
                />
              </Stack>
              <Typography py={2} variant={'subtitle1'}>
                Home Address
              </Typography>
              <Stack direction={'row'} flexWrap={'wrap'} gap={2} width={'100%'}>
                <Stack
                  gap={2}
                  flexGrow={1}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <InputField
                    control={control}
                    name="address.streetAddress"
                    label="Street Address"
                  />
                  <InputDropdown
                    name="address.state"
                    label="State *"
                    control={control}
                    options={States}
                  />
                </Stack>
                <Stack
                  gap={2}
                  flexGrow={1}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <InputField
                    control={control}
                    name="address.zipCode"
                    label="Zip code"
                  />
                  <InputField
                    control={control}
                    name="address.city"
                    label="City"
                  />
                </Stack>
              </Stack>
              <Typography variant={'h6'} py={2}>
                Real Estate information
              </Typography>
              <Stack
                direction={'row'}
                gap={2}
                width={'100%'}
                pb={2}
                flexWrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <InputField
                  control={control}
                  name="licenseNumber"
                  label="Real Estate License #"
                  rules={{
                    required: 'Please input user license number',
                  }}
                />
                <InputDropdown
                  name={'licenseState'}
                  label="License State *"
                  control={control}
                  options={LicenseStates}
                  rules={{
                    required: 'Please select license state',
                  }}
                />
                <DatePickerInput
                  name="licenseExpirationDate"
                  label="License Expiration Date"
                  control={control}
                />
              </Stack>
              <Stack
                direction={'row'}
                gap={2}
                width={'100%'}
                flexWrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <InputField
                  control={control}
                  name="localAssociation"
                  label="Primary Local Association *"
                  rules={{
                    required: 'Please enter user primary local association',
                  }}
                />
                <InputField
                  control={control}
                  name="stripeId"
                  label="Strpe id"
                />
              </Stack>
            </CardContent>
            <CardActions>
              <Stack direction="row" flexWrap={'wrap'} gap={'1rem'} mt={1}>
                <Stack direction="row" flexWrap={'wrap'} gap={'1rem'}>
                  <LoadingButton
                    type="submit"
                    loading={userDataFetchStatus === 'loading'}
                    variant={'contained'}
                  >
                    {isEdit ? 'Save changes' : 'Add user'}
                  </LoadingButton>
                  {isEdit && (
                    <>
                      <LoadingButton
                        variant={'outlined'}
                        loading={docusignGenerationStatus === 'loading'}
                        onClick={generateDocusignDocuments}
                      >
                        Generate DocuSign documents
                      </LoadingButton>
                      <LoadingButton
                        variant={'contained'}
                        sx={{ backgroundColor: palette.error.main }}
                        onClick={() => {
                          if (userId) {
                            deleteMutation.mutate(userId, {
                              onSuccess: data => {
                                snackbar.showSnackBar(
                                  'User deleted successfully.',
                                  'success',
                                );
                                history.push(RouteConstants.admin.users);
                              },
                              onError: error => {
                                snackbar.showSnackBar(
                                  'Error while deleting the user.',
                                  'error',
                                );
                              },
                            });
                          }
                        }}
                        loading={deleteMutation.isLoading}
                      >
                        DELETE
                      </LoadingButton>
                    </>
                  )}
                  <Button
                    component={RouterLink}
                    to={RouteConstants.admin.users}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default UserAddEdit;
